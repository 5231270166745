header {
  height: 60px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(140deg, #29edfc, #4382f4);
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.2);

  .hamburger {
    width: 50px;
  }

  .logo {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }

  .user {
    line-height: 0;
    .icon {
      margin-right: 10px;
      display: inline;
      font-size: 35px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
