// flex
.flex {
  display: flex;
}

.flex-dir {
  &-column {
    flex-direction: column;
  }
}

// justify-content
$j-contents: center, end, space-between, space-around;
@each $j-content in $j-contents {
  .j-content-#{$j-content} {
    justify-content: $j-content;
  }
}

.align-items {
  &-center {
    align-items: center !important;
  }
}

.align-self {
  &-end {
    align-self: flex-end;
  }
}

// text
.text {
  &-center {
    text-align: center;
  }
}

.white-space {
  &-no {
    white-space: nowrap;
  }
}

.ellipsis {
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer;
}

// width, height, margin, padding
@for $i from 1 through 500 {
  .h-#{$i}-vh {
    height: $i + vh !important;
  }

  .h-#{$i}-percent {
    height: $i * 1% !important;
  }

  .w-#{$i}-percent {
    width: $i * 1% !important;
  }

  .w-#{$i} {
    width: $i + px !important;
  }

  .max-w-#{$i} {
    max-width: $i + px !important;
  }

  .mt-#{$i} {
    margin-top: $i + px !important;
  }

  .mb-#{$i} {
    margin-bottom: $i + px !important;
  }

  .mr-#{$i} {
    margin-right: $i + px !important;
  }

  .p-#{$i} {
    padding: $i + px !important;
  }

  .pb-#{$i} {
    padding-bottom: $i + px !important;
  }

  .pt-#{$i} {
    padding-top: $i + px !important;
  }

  .fs-#{$i} {
    font-size: $i + px !important;
  }
}

.m-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

// colors
$colors: (
  aqua: $aqua, red: red, orange: orange, green: green, white: white, yellow: #ffd600,
  black: black, grey: grey, blue: blue
);
@each $key, $val in $colors {
  .color-#{$key} {
    color: $val;
  }

  .background-#{$key} {
    background-color: $val;
  }
}

// overflow
$overflows: auto, hidden;
@each $overflow in $overflows {
  .overflow-#{$overflow} {
    overflow: $overflow;
  }
}
