.table {
  &.zebra {
    tbody {
      tr {
        &:nth-of-type(odd) {
          background-color: whitesmoke;
        }
      }
    }
  }
}