.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &.h-200px {
    height: 200px;
  }
  &.fixed {
    position: fixed;
    z-index: 1400;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.6);
  }
}
