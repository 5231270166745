a {
  text-decoration: none;
}

h4,
h3,
h2,
p {
  margin: 0;
}

.MuiPaper-root.MuiDrawer-paper {
  width: 50%;

  @media all and (max-width: 767px) {
    width: 70%;
  }
}

.content {
  height: calc(100vh - 60px);
  display: flex;
  overflow: auto;

  &.simple {
    height: calc(95vh - 60px);
  }

  &:has(.menu-closed) {
    aside {
      width: 5%;
      overflow: hidden;
    }

    .content-wrap {
      width: 95%;
    }
  }

  aside {
    background-color: #FFFFFF;
    width: 20%;
    z-index: 1;
    transition: width 1s;
    box-shadow: 5px 0 7px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 5px 0 7px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 5px 0 7px 0px rgba(0, 0, 0, 0.15);

    @media all and (max-width: 1200px) {
      width: 30%;
    }
  }

  &-wrap {
    width: 80%;
    margin-top: 10px;
    // z-index: 2;
    transition: width 1s;
    background-color: #FCFCFC;

    @media all and (max-width: 1200px) {
      width: 70%;
    }

    @media all and (max-width: 1023px) {
      width: 100%;
    }

    main {
      height: calc(100vh - 110px); // 110px: header - 60px, footer - 50px
    }
  }

  &.mobile-menu {
    height: 100vh;

    aside {
      width: 100%;

      .sidebar {
        height: calc(100vh - 50px);
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }
}

.err-mess {
  color: red;
}

.profile-form {
  width: 35%;
  display: flex;
  flex-direction: column;

  @media all and (max-width: 1199px) {
    width: 50%;
  }

  @media all and (max-width: 767px) {
    width: 80%;
  }
}

.link-a {
  color: goldenrod;
}

.search {
  display: flex;
  justify-content: flex-end;

  input {
    width: 30%;
    min-width: 300px;
  }

  button {
    margin-left: 10px;
  }
}

.code-two-fa {
  input {
    width: 120px;
    text-align: center;
    font-size: 30px;
  }

  label {
    top: 10px;

    &.Mui-focused,
    &.MuiFormLabel-filled {
      top: 0;
    }
  }
}
