.switch-menu-section {
  height: 50px;
  width: 40px;
  padding: 20px;
  color: #000000;
  
  .menu-icon {
    cursor: pointer;
  }
}

.sidebar {
  height: calc(100vh - 110px); // 100px: .switch-menu-section + header
  padding-bottom: 10px;
  overflow: auto;
  color: #000000;

  .item {
    width: 100%;
    min-width: 200px;
    margin-left: -15px;
    position: relative;

    h4 {
      height: 35px;
      color: white;
    }

    &-expand {
      position: absolute;
      top: 15px;
      right: 0;
    }

    &-link {
      width: 100%;
      height: 50px;
      padding-left: 20px;
      display: block;
      line-height: 3;
      color: gainsboro;
      
      &.active {
        background: brown;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }
    }

    .collapse {
      .item-link {
        padding-left: 30px;
      }

      h4 {
        margin: 15px 0 10px 0;
        padding-left: 20px;
      }
    }
  }
}
